import { Box, Flex, Text } from '@chakra-ui/layout'
import GameBoard from 'components/game/GameBoard'


const TBAResult = ({ league, userSets, isToday }) => (
  <Box>
    <GameBoard 
      league={league}
      disabled
      players={(userSets && userSets.selected_players.filter(p => p.is_selected)) || []}
    />
    <Flex
      justifyContent="center"
      textTransform="uppercase"
      gap="55px"
      mt="30px"
    >
      <Box flexBasis="120px">
        <Text
          color="var(--chakra-colors-white80)"
          fontSize="14px"
          fontWeight="600"
          align="center"
          lineHeight="1.2"
        >YOUR SCORE</Text>
        <Text
          color="var(--chakra-colors-white)"
          fontSize="24px"
          fontWeight="800"
          align="center"
          lineHeight="1.25"
        >{userSets.place}</Text>
      </Box>
      <Box flexBasis="120px">
        <Text
            color="var(--chakra-colors-white80)"
            fontSize="14px"
            fontWeight="600"
            align="center"
            lineHeight="1.2"
          >RANK</Text>
          <Text
            color="var(--chakra-colors-white)"
            fontSize="24px"
            fontWeight="800"
            align="center"
            lineHeight="1.25"
          >{userSets.total_points}</Text>
      </Box>
    </Flex>
    <Flex
      align="center"
      mt="30px"
      alignItems="center"
      justifyContent="center"
      fontSize="16px"
      fontWeight="700"
      color="white"
      height="130px"
      p="10px"
      borderRadius="12px"
      bg="var(--chakra-colors-white5)"
    >
      {league === 'nfl' ? 'Most yards wins. Results pending' : 'Most points wins. Results pending'}
    </Flex>
  </Box>
)


export default TBAResult
