import { Box, Text } from '@chakra-ui/react'
import SignIn from 'components/auth/SignIn'
import routes from 'constants/routes'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { selectUser } from 'store/slices/authSlice'
import * as Sentry from '@sentry/react';


const SignInPage = () => {
  const user = useSelector(selectUser)
  const [params] = useSearchParams()

  const returnUrl = params && params.get('redirect')

  if (user) {
    return <Navigate to={returnUrl || routes.game} />
  }
  
  return (
    <Box>
      <Text
        color="#78BEFF"
        fontWeight="800"
        fontSize="32px"
        align="center"
      >
        Sign in
      </Text>

      <Text
        color="var(--chakra-colors-white80)"
        fontWeight="700"
        fontSize="18px"
        align="center"
        mt="10px"
      >
        Track your games and results
      </Text>
      <Sentry.ErrorBoundary fallback={<Box></Box>}>
        <SignIn mt="30px" />
      </Sentry.ErrorBoundary>
    </Box>
  )
}

export default SignInPage