import dayjs from 'dayjs'
import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@chakra-ui/react'
import { Box, Text, Flex, Center } from '@chakra-ui/layout'
import Prize from 'components/prize/Prize'
import Preloader from 'components/Preloader'
import { useLeaderboardListQuery, useWeeklyPrizesQuery } from 'store/services/api'
import { selectUser } from 'store/slices/authSlice'
import { getDayNumber } from 'utils'
import DailySwitcher from './DaySwitcher'
import EmptyMessage from './EmptyMessage'
import TBAResult from './TBAResult'
import Winners from './Winners'
import routes from 'constants/routes'
import { useGetUserProfileQuery } from 'store/services/users'


const DailyLeaderboard = ({ isBlurred }) => {
  const [currentDate, setCurrentDate] = useState(dayjs().tz('America/New_York'))
  const user = useSelector(selectUser)
  
  const { data, isFetching } = useLeaderboardListQuery(currentDate)
  const { profile, isFetching: isProfileFetching } = useGetUserProfileQuery(undefined, { skip: !user })

  const { data: prizes, isLoading: isPrizesLoading } = useWeeklyPrizesQuery()
  
  const dayOfTheWeek = useMemo(getDayNumber, [])
  
  const isLoading = isPrizesLoading || isFetching || !data || isProfileFetching
  
  const message = data?.message
  
  const userSets = useMemo(() => {
    if (isLoading) return null
    const fromData = data?.user_sets
    if (fromData) return fromData
    const currentDateStr = currentDate.format('YYYY-MM-DD')
    const fromUser = profile?.recent_games?.find(g => g.date === currentDateStr)
    if (fromUser) {
      return fromUser
    }
    return null
  }, [data, profile, isLoading, currentDate])

  const otherSets = data?.other_sets
  const place = userSets?.place
  const isTBA = isLoading ? (place === 'TBA' || place === 'TBD') : false
  const prize = prizes?.find(p => p.day_of_week === dayOfTheWeek)

  useEffect(
    () => {
      if (data) {
        dayjs(data.date).format('YYYY-MM-DD') !== dayjs(currentDate).format('YYYY-MM-DD') && setCurrentDate(dayjs(data.date))
      }
    },
    [data]
  )

  const content = useMemo(
    () => {
      if (userSets || otherSets) {
        if (isTBA) {
          return  (
            <TBAResult 
              userSets={userSets} 
              league={data.league}
              isToday={currentDate.isToday()}
            />
          )
        } else {
          return (
            <Box
              pos="relative"
              userSelect="none"
              minH="200px"
              pt="20px"
            >
              <Winners 
                isToday={currentDate.isToday()}
                userSets={userSets} 
                otherSets={otherSets || []}
                prize={prize}
                league={data.league}
              />
              {isBlurred && (
                <Flex
                  pos="absolute" 
                  top="0px"
                  left="-20px"
                  right="-20px"
                  bottom="-20px"
                  backdropFilter="blur(10px)"
                  align="center"
                  justify="center"
                  
                >
                  <Box>
                    <Text 
                      align="center"
                      fontWeight="700"
                      fontSize="18px"
                      lineHeight="22px"
                    >
                      Fill in your profile<br />to check the leaderboard 
                    </Text>
                    <Center>
                      <Button
                        variant="blue"
                        as={Link}
                        to={user ? routes.settings : routes.signin}
                        mt="20px"
                      >Jump to Profile</Button>
                    </Center>
                  </Box>
                </Flex>
              )}
            </Box>
          )
        }
      } else {
        return <EmptyMessage message={message} />
      }
    },
    [currentDate, isTBA, data, isBlurred, prize, user]
  )
  
  return (
    <Box>
      {!isLoading  && (
        <DailySwitcher
          league={data.league.toUpperCase()}
          date={currentDate} 
          nextDate={data.next && dayjs(data?.next)} 
          prevDate={data.previous && dayjs(data.previous)} 
          countUserSets={data.count_user_sets}
          onPrev={() => {
            if (!isLoading) {
              data.previous && setCurrentDate(dayjs(data.previous))
            }
          }}
          onNext={() => {
            if (!isLoading) {
              data.next && setCurrentDate(dayjs(data.next))
            }
          }}
        />
      )}
      {isLoading ? <Preloader /> : (
        <>
          {content}
          {prize && currentDate.isToday() && (
            <>
              <Box mt="50px">
                <Text
                  fontSize="24px"
                  fontWeight="800"
                  align="center"
                >Winner’s prize</Text>
              </Box>
              <Box mt="20px">
                <Prize mininal prize={prize} />
              </Box>
            </>
          )}
        </>
      )}
        
    </Box>
  )
};

export default DailyLeaderboard



