import { Box, Text } from '@chakra-ui/layout'
import DailyLeaderboard from 'components/leaderboard/DailyLeaderboard'
import MonthlyLeaderboard from 'components/leaderboard/MonthlyLeaderboard'
import Switcher from 'components/leaderboard/Switcher';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/slices/authSlice';


const LeaderboardPage = () => {
  const [index, setIndex] = useState(0)
  const user = useSelector(selectUser)  
  const isBlurred = !user?.username
  return (
    <Box>
      <Text
        fontWeight="800"
        fontSize="32px"
        color="#78BEFF"
        align="center"
      >
        Leaderboard
      </Text>
      <Switcher
        index={index}
        onChange={setIndex}
      />
      <Box mt="15px" minH="200px">
        <Box display={index === 0 ? 'block' : 'none'}>
          <DailyLeaderboard isBlurred={isBlurred} />
        </Box>
        <Box display={index === 1 ? 'block' : 'none'}>
          <MonthlyLeaderboard isBlurred={isBlurred} />
        </Box>
      </Box>
    </Box>
  )
};

export default LeaderboardPage


