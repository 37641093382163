export const nbaPlayers = [
  {
    "rapid_id": 428,
    "first_name": "Jakob",
    "last_name": "Poeltl",
    "full_name": "Jakob Poeltl",
    "team_name": "Toronto Raptors",
    "team_logo": "https://sfo3.digitaloceanspaces.com/fms5-card-images/NBA_TOR.png",
    "team_color": "BE0F34",
    "headshot": "https://sfo3.digitaloceanspaces.com/fms5-card-images/jakob-poeltl-428.png",
    "headshot_webp": "/images/players/nba/01.webp",
    "jersey_number": "25",
    "position": "center",
    is_grey: true,            
  },
  {
    "rapid_id": 124,
    "first_name": "Stephen",
    "last_name": "Curry",
    "full_name": "Stephen Curry",
    "team_name": "Golden State Warriors",
    "team_logo": "https://sfo3.digitaloceanspaces.com/fms5-card-images/NBA_GSW.png",
    "team_color": "FDB927",
    "headshot": "https://sfo3.digitaloceanspaces.com/fms5-card-images/stephen-curry-124.png",
    "headshot_webp": "/images/players/nba/04.webp",
    "jersey_number": "30",
    "position": "point guard",
  },
  {
    "rapid_id": 2040,
    "first_name": "Luguentz",
    "last_name": "Dort",
    "full_name": "Luguentz Dort",
    "team_name": "Oklahoma City Thunder",
    "team_logo": "https://sfo3.digitaloceanspaces.com/fms5-card-images/NBA_OKC.png",
    "team_color": "F05133",
    "headshot": "https://sfo3.digitaloceanspaces.com/fms5-card-images/luguentz-dort-2040.png",
    "headshot_webp": "/images/players/nba/02.webp",
    "jersey_number": "5",
    "position": "Guard",
    is_grey: true,
  },
  {
    "rapid_id": 1046,
    "first_name": "Trae",
    "last_name": "Young",
    "full_name": "Trae Young",
    "team_name": "Atlanta Hawks",
    "team_logo": "https://sfo3.digitaloceanspaces.com/fms5-card-images/NBA_ATL.png",
    "team_color": "E13A3E",
    "headshot": "https://sfo3.digitaloceanspaces.com/fms5-card-images/trae-young-1046.png",
    "headshot_webp": "/images/players/nba/03.webp",
    "jersey_number": "11",
    "position": "point guard",    
  },
  {
    "rapid_id": 142,
    "first_name": "Spencer",
    "last_name": "Dinwiddie",
    "full_name": "Spencer Dinwiddie",
    "team_name": "Brooklyn Nets",
    "team_logo": "https://sfo3.digitaloceanspaces.com/fms5-card-images/NBA_BKN.png",
    "team_color": "008CA8",
    "headshot": "https://sfo3.digitaloceanspaces.com/fms5-card-images/spencer-dinwiddie-142.png",
    "headshot_webp": "/images/players/nba/05.webp",
    "jersey_number": "26",
    "position": "point guard"
          
  }
]

export const nflPlayers = [
  {
    "rapid_id": 2,
    "first_name": "Jalen",
    "last_name": "Hurts",
    "full_name": "Jalen Hurts",
    "team_name": "Philadelphia Eagles",
    "team_logo": "/images/players/nfl/NFL_PHI.svg",
    "team_color": "004953",
    "headshot": "/images/players/nfl/jalen-hurts.webp",
    "headshot_webp": "/images/players/nfl/jalen-hurts.webp",
    "jersey_number": "1",
    "position": "Quarterback",
    is_grey: true,            
  },
  {
    "rapid_id": 1,
    "first_name": "Patrick",
    "last_name": "Mahomes",
    "full_name": "Patrick Mahomes",
    "team_name": "Kansas City Chiefs",
    "team_logo": "/images/players/nfl/NFL_KC.svg",
    "team_color": "E31837",
    "headshot": "/images/players/nfl/patrick-mahomes.webp",
    "headshot_webp": "/images/players/nfl/patrick-mahomes.webp",
    "jersey_number": "15",
    "position": "Quarterback",
    is_grey: true,            
  },
  {
    "rapid_id": 4,
    "first_name": "Lamar",
    "last_name": "Jackson",
    "full_name": "Lamar Jackson",
    "team_name": "Baltimore Ravens",
    "team_logo": "/images/players/nfl/NFL_BAL.svg",
    "team_color": "241773",
    "headshot": "/images/players/nfl/lamar-jackson.webp",
    "headshot_webp": "/images/players/nfl/lamar-jackson.webp",
    "jersey_number": "8",
    "position": "Quarterback",
    is_grey: true,            
  },
  {
    "rapid_id": 3,
    "first_name": "Joe",
    "last_name": "Burrow",
    "full_name": "Joe Burrow",
    "team_name": "Cincinnati Bengals",
    "team_logo": "/images/players/nfl/NFL_CIN.svg",
    "team_color": "FB4F14",
    "headshot": "/images/players/nfl/joe-burrow.webp",
    "headshot_webp": "/images/players/nfl/joe-burrow.webp",
    "jersey_number": "9",
    "position": "Quarterback",
    is_grey: true,            
  },
  {
    "rapid_id": 5,
    "first_name": "Dak",
    "last_name": "Prescott",
    "full_name": "Dak Prescott",
    "team_name": "Dallas Cowboys",
    "team_logo": "/images/players/nfl/NFL_DAL.svg",
    "team_color": "002244",
    "headshot": "/images/players/nfl/dak-prescott.webp",
    "headshot_webp": "/images/players/nfl/dak-prescott.webp",
    "jersey_number": "4",
    "position": "Quarterback",
    is_grey: true,            
  },
 
]


export const mlbPlayers = [
  {
    "rapid_id": 3,
    "first_name": "Corey",
    "last_name": "Seager",
    "full_name": "Corey Seager",
    "team_name": "Texas Rangers",
    "team_logo": "/images/players/mlb/MLB_TEX.svg",
    "team_color": "003278",
    "headshot": "/images/players/mlb/corey-seager.webp",
    "headshot_webp": "/images/players/mlb/corey-seager.webp",
    "jersey_number": "5",
    "position": "Shortstop",
    is_grey: true,            
  },
  {
    "rapid_id": 2,
    "first_name": "Aaron",
    "last_name": "Judge",
    "full_name": "Aaron Judge",
    "team_name": "New York Yankees",
    "team_logo": "/images/players/mlb/MLB_NYY.svg",
    "team_color": "0F1C43",
    "headshot": "/images/players/mlb/aaron-judge.webp",
    "headshot_webp": "/images/players/mlb/aaron-judge.webp",
    "jersey_number": "99",
    "position": "Right Fielder",
    is_grey: true,            
  },
  {
    "rapid_id": 5,
    "first_name": "Ronald",
    "last_name": "Acuña Jr.",
    "full_name": "Ronald Acuña Jr.",
    "team_name": "Atlanta Braves",
    "team_logo": "/images/players/mlb/MLB_ATL.svg",
    "team_color": "13274F",
    "headshot": "/images/players/mlb/ronald.webp",
    "headshot_webp": "/images/players/mlb/ronald.webp",
    "jersey_number": "13",
    "position": "Right Fielder",
    is_grey: true,            
  },
  {
    "rapid_id": 1,
    "first_name": "Shohei",
    "last_name": "Ohtani",
    "full_name": "Shohei Ohtani",
    "team_name": "Los Angeles Dodgers",
    "team_logo": "/images/players/mlb/MLB_LAD.svg",
    "team_color": "005A9C",
    "headshot": "/images/players/mlb/shohei-ohtani.webp",
    "headshot_webp": "/images/players/mlb/shohei-ohtani.webp",
    "jersey_number": "17",
    "position": "Designated Hitter",
    is_grey: true,            
  },
  {
    "rapid_id": 4,
    "first_name": "Bryce",
    "last_name": "Harper",
    "full_name": "Bryce Harper",
    "team_name": "Philadelphia Phillies",
    "team_logo": "/images/players/mlb/MLB_PHI.svg",
    "team_color": "E81828",
    "headshot": "/images/players/mlb/bryce-harper.webp",
    "headshot_webp": "/images/players/mlb/bryce-harper.webp",
    "jersey_number": "3",
    "position": "First Base",
    is_grey: true,            
  },
]