import { Flex, Text } from '@chakra-ui/layout'
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg'
import { ReactComponent as RightArrow } from 'icons/right-arrow.svg'





const DailySwitcher = ({ countUserSets, league, date, prevDate, nextDate, onPrev, onNext }) => {
  return (
    <Flex
      py="8px"
      alignItems="center"
      justifyContent="center"
      gap="25px"
    >
      <LeftArrow 
        onClick={prevDate ? onPrev : undefined}
        style={{
          cursor: prevDate ? 'pointer' : 'default',
          opacity: prevDate ? '1' : '0.2'
        }}
      />
      <Flex
        color="var(--chakra-colors-white80)"
        fontSize="18px"
        fontWeight="700"
        alignItems="center"
        gap="10px"
        justifyContent="center"
        width="275px"
      >
        {countUserSets === undefined ? (
          <>
            <Text>{date ? date.format('ddd M/D') : ""}</Text>
            <Text>·</Text>
            <Text>{league}</Text>
          </>
        ) : (
          <>
            <Text>{date ? date.format('ddd (M/D)') : ""} {league}</Text>
            <Text>·</Text>
            <Text>{countUserSets} Hands</Text>
          </>
        )}
      </Flex>
      <RightArrow
        onClick={nextDate ? onNext : undefined}
        style={{
          cursor: nextDate ? 'pointer' : 'default',
          opacity: nextDate ? '1' : '0.2'
        }}
      />
    </Flex>
  )
}

export default DailySwitcher