import { Box, Flex, Text } from '@chakra-ui/react'
import { numberSuffix } from 'utils'
import GameCard from './game/GameCard'
import dayjs from 'dayjs'


const GamesTable = ({ games }) => {
  
  return (
    <Box mt="30px">
      {games.map((game, index) => {
        const isFirst = !!game.is_first
        return (
          <Flex
            key={game.id}
            justifyContent="space-between"
            alignItems="stretch"
            p="10px 15px"
            borderRadius="14px"
            bg={isFirst ? "var(--chakra-colors-white5)" : "transparent"}
            mt={index > 0 ? "20px" : "0px"}
            border={isFirst ? "1px solid var(--chakra-colors-yellow)" : "1px solid var(--chakra-colors-white10)"}
            color={isFirst ? "var(--chakra-colors-yellow)" : "white"} 
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              maxWidth="calc(100% - 188px)"
            >
              <Text
                fontSize="13px"
                fontWeight="600"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >{dayjs(game.date).format('MM/DD/YY')}</Text>            
              <Box>
                <Text
                  textTransform="uppercase"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="17px"
                  color={isFirst ? "var(--chakra-colors-yellow)" : "var(--chakra-colors-white40)"}
                >{numberSuffix(game.place)}{isFirst && <> 🏆</>}</Text>
                
                <Text
                  lineHeight="26px"
                  fontSize="20px"
                  fontWeight="800"
                >
                  {game.total_points} pts
                </Text>
              </Box>
              
            </Flex>
            
            <Flex gap="8px">
              {game.selected_players.filter(p => p.is_selected).map(p => (
                <Box key={p.rapid_id}>
                  <GameCard
                    placed
                    flipped
                    player={p}
                    selected
                    small
                  />
                  <Box 
                    fontWeight="600" 
                    fontSize="12px" 
                    mt="6px" 
                    lineHeight="12px" 
                    textAlign="center"
                    color="white"
                  >{p.points}</Box>
                </Box>
              ))}
            </Flex>
            
          </Flex>)
      })}
    </Box>
  )
}

export default GamesTable