import dayjs from 'dayjs'
export * from './markup'
export * from './shuffle'
export * from './removeEmpty'
export * from './renderer'


export function numberSuffix(str) {
  return str
  // let numberPart = str.match(/\d+/)
  // const n = numberPart ? parseInt(numberPart[0]) : str;

  // const lastDigit = n % 10;
  // const lastTwoDigits = n % 100;

  // if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
  //   return `${str}th`;
  // } else if (lastDigit === 1) {
  //   return `${str}st`;
  // } else if (lastDigit === 2) {
  //   return `${str}nd`;
  // } else if (lastDigit === 3) {
  //   return `${str}rd`;
  // } else {
  //   return `${str}th`;
  // }
}

export function getDayNumber() {
  const day = dayjs().day() - 1
  if (day < 0) return 6
  return day
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}