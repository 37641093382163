import { Box, Button, Flex } from '@chakra-ui/react'
import UserSubsctiption from 'components/refferal/UserSubsctiption'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { useGetUserRecentRefferalsQuery, useGetUserRefferalCodeQuery } from 'store/services/users'
import { selectUser } from 'store/slices/authSlice'
import { logError } from 'utils/logger'


export async function copyToClipboard(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy).catch(err => console.error(err))
  } else {
    const textArea = document.createElement("textarea")
    textArea.value = textToCopy

    textArea.style.position = "absolute"
    textArea.style.left = "-999999px"

    document.body.prepend(textArea)
    textArea.select()

    try {
      document.execCommand('copy')
    } catch (error) {
      console.error(error)
    } finally {
      textArea.remove()
    }
  }
}

const RefferalSettingsPage = () => {
  const user = useSelector(selectUser)
  const [copied, setCopied] = useState(false)

  const recentRefferalsQuery = useGetUserRecentRefferalsQuery(undefined, { skip: !user })

  const { data: code, isFetching, refetch } = useGetUserRefferalCodeQuery(undefined, { skip: !user })


  useEffect(
    () => {
      if (copied) {
        setTimeout(() => setCopied(false), 4000)
      }
    },
    [copied]
  )

  if (!user) {
    return <Navigate to="/" />
  }

  if (recentRefferalsQuery.isFetching) return null

  const displayHistory = user.user_swaps > 0 && recentRefferalsQuery.data?.length > 0


  return (
    <Box
      height="100%"
      top="0"
      left="0"
      width="100%"
      minHeight="calc(100vh - 256px)"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box 
        position={displayHistory ? 'relative' : 'absolute'}
        top={displayHistory ? '0' : '50%'}
        transform={displayHistory ? 'none' : 'translateY(-50%)'}
        textAlign="center"
        pt={displayHistory ? '20px' : '0px'}
        left="0"
        width="100%"
        px="16px"
      >
        <Box
          fontWeight="800"
          fontSize="24px"
          lineHeight="30px"
          maxW="307px"
          mx="auto"
        >
          Text friends your link & earn swaps
        </Box>
        <Box
          mt="10px"
          fontWeight="700"
          fontSize="16px"
          lineHeight="22px"
          color="var(--chakra-colors-white80)"
          maxW="310px"
          mx="auto"
          textAlign="center"
        >
          1 Referral = 3 Swaps
        </Box>
        <Box mt="30px" textAlign="center">
          <Button
            variant="blue"
            isDisabled={isFetching || copied}
            opacity="1"
            onClick={async e => {
              e.preventDefault()
              if (!code) return
              copyToClipboard(code.referral_link)
              setCopied(true)
              try {
                refetch().catch(err => logError(err, {
                  component: 'RefferalSettingsPage',
                  place: 'Copy Link onClick' 
                }))
              } catch (err) {
                console.error(err)
              }
            }}
          >{copied ? 'Copied!' : 'Copy Link'}</Button>
        </Box>
        <UserSubsctiption />
        
      </Box>

      {displayHistory && (
        <Box
          px="16px"
        >
          <Box
            mt="40px"
            bg="var(--chakra-colors-white5)"
            borderTopRadius="8px"
            p="25px 15px"
          >
            <Box
              fontWeight="800"
              fontSize="20px"
              lineHeight="24px"
            >
              {user.user_swaps} swaps available
            </Box>
            <Box
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              mt="10px"
              letterSpacing="0.02em"
              color="var(--chakra-colors-white40)"
            >
              RECENT REFERRALS
            </Box>

            <Flex
              flexDirection="column"
              gap="15px"
              mt="20px"
            >
              {recentRefferalsQuery.data.map((el, index) => (
                <RefferalRow data={el} key={index} />
              ))}

            </Flex>

            <Box
              fontWeight="700"
              fontSize="13px"
              lineHeight="18px"
              mt="20px"
              color="var(--chakra-colors-white40)"
            >
              Players may only use 1 swap per day
            </Box>

          </Box>
        </Box>
      )}
    </Box>
  )
}

export default RefferalSettingsPage

const RefferalRow = ({ data }) => (
  <Flex
    textAlign="left"
    fontSize="14px"
    fontWeight="700"
    width="100%"
  >
    <Box
      flexBasis="70px"
      flexShrink="0"
      color="white80"
    >{dayjs(data.created_at).format('M/D/YY')}</Box>
    <Box
      flexBasis="100%"
      color="white"
    >{data.phone_number}</Box>
    <Box
      flexShrink="0"
      color="#77BC1F"
      flexBasis="42px"
      textAlign="right"
    >{data.count_added_swaps}</Box>
  </Flex>
)