import { Box, Button, Center, Flex, Text } from '@chakra-ui/react'
import GameCard from '../game/GameCard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { sleep } from 'utils'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'
import { useGameQuery } from 'store/services/api'
import { mlbPlayers, nbaPlayers, nflPlayers } from './players'




const LS_KEY = '5_CARDS_DRAW_ONBOARDING_SHOWED'
const flipDuration = 2500

const Onboarding = ({ onClose }) => {
  const { data: game, isLoading: isGameLoading } = useGameQuery()
  const [isOpen, setIsOpen] = useState(false)
  const [placed, setPlaced] = useState(false)
  const [animCompleted, setAnimCompleted] = useState(false)
  const [flipped, setFlipped] = useState(false)
  const [selected, setSelected] = useState([])

  const key = `${game?.league}_${LS_KEY}`;

  const close = useCallback(() => {
    if (!game?.league) return
    localStorage.setItem(key, '1')
    setIsOpen(false)
    onClose()
  }, [key])

  useEffect(
    () => {
      const isOpen = localStorage.getItem(key) !== '1'
      setIsOpen(isOpen)
      if (!isOpen) {
        onClose()
      }
    },
    [key]
  )

  useEffect(
    () => {
      if (!isOpen) return
      if (!placed && !animCompleted) {
        setTimeout(() => setPlaced(true), 1000)
      }
    },
    [isOpen, placed, animCompleted]
  )

  useEffect(
    () => {
      if (animCompleted || !isOpen || (!placed && flipped)) return
      setTimeout(async () => {
        setFlipped(true)
        setAnimCompleted(true)
        await sleep(2000)
        for (const i of [1, 3, 4]) {
          await sleep(300)
          setSelected(value => [...value, i])
        }
        await sleep(2000)
        setFlipped(false)
        await sleep(0)
        setPlaced(false)
        setSelected([])
        await sleep(2000)
        setAnimCompleted(false)
      }, flipDuration)
    },
    [isOpen, placed, flipped, animCompleted]
  )

  const text = useMemo(
    () => {
      if (!game?.league)  return null
      if (game.league === 'mlb') {
        return <>Pick the players who you<br />think get the most total bases</>
      } else if( game.league === 'nfl') {
        return <>Pick the players who you<br />think have the most yards</>
      } else if( game.league === 'nba') {
        return <>Pick the players who you<br />think will score the most points</>
      }
    },
    [game?.league]
  )

  const players = useMemo(
    () => {
      
      if (!game?.league)  return []
      if (game.league === 'mlb') {
        return mlbPlayers
      } else if( game.league === 'nfl') {
        return nflPlayers
      } else if( game.league === 'nba') {
        return nbaPlayers
      }
    },
    [game?.leagu]
  )

  if (!isOpen || isGameLoading) return null

  
  const width = Math.min(window.innerWidth, 400) - 28
  return (
    <Box>
      <Flex 
        justifyContent="center" 
        flexWrap="wrap" 
        gap="10px" 
        mx="auto"
        bg="#FFFFFF0F"
        py="20px"
        borderRadius="10px"
      >
        {players.map((player, index) => (
          <GameCard
            width={width}
            key={player.rapid_id}
            placed={placed}
            flipped={flipped}
            player={player}
            index={index}
            grayScaled={true}
            disabled={false}
            selected={selected.indexOf(index) !== -1}
          />
        ))}
      </Flex>

      <Box 
        mt="20px" 
        textAlign="center"
      >
        <Text
          size="title"
          color="white"
        >How to play</Text>
        <Text 
          mt="15px"
          size="text"
          color="white"
        >{text}</Text>
      </Box>

      
      <Center mt="25px">
        <Button 
          w="200px" 
          variant="blue" 
          onClick={close}
        >
          Play for free
        </Button>
      </Center>
      <Text
        fontSize="14px"
        fontWeight="600"
        color="#FFFFFF99"
        align="center"
        mt="20px"
        maxW="335px"
        mx="auto"
        sx={{
          '& a': { color: 'var(--chakra-colors-blue)' }
        }}
      >
        By continuing, I confirm that I am 13 years of age or older and accept the <Link to={routes.rules}>Official rules</Link>.
      </Text>
    </Box>
  )
}

export default Onboarding