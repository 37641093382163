import { Box, Flex } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as UserIcon } from 'icons/user.svg'
import { ReactComponent as SwapsIcon } from 'icons/swaps.svg'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/slices/authSlice'
import { useGetUserInfoQuery } from 'store/services/users'
import { ReactComponent as LogoIcon } from 'icons/logo.svg'
import routes from 'constants/routes'
import Menu from './Menu'


const Header = () => {
  const user = useSelector(selectUser)
  
  const { pathname } = useLocation()
  
  return (
    <Flex 
      pos="relative"    
      px="15px" 
      justifyContent="space-between" 
      alignItems="center" 
      h="48px"
      zIndex="200"
    >
      <Flex 
        justifyContent="flex-start"
        alignItems="center"
        h="100%"
        gap="5px"
        flexShrink="0"
      >
        <Menu />
        <Link to={routes.home}>
          <LogoIcon />
        </Link>
      </Flex>
      <Flex
        alignItems="center"
        h="100%"
        justifyContent="flex-end"
        flexBasis="100%"
        gap="15px"
      >
        {user && (
          <Link to={routes.referralSetting}>
            <Flex gap="5px">
              <SwapsIcon />
              <Box
                fontSize="15px"
                fontWeight="700"
              >{user.user_swaps}</Box>
            </Flex>
          </Link>
        )}
        <Link
          to={user ? routes.settings : `${routes.signin}?redirect=${pathname}`}
        >
          <UserIcon />
        </Link>
      </Flex>
    </Flex>
  )
}

export default Header