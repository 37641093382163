import { useState } from 'react'
import dayjs from 'dayjs'
import Prize from 'components/prize/Prize'
import { useMemo } from 'react'
import Preloader from 'components/Preloader'
import {  useMonthlyLeaderboardListQuery, useMonthlyPrizesQuery, useUserMonthlyLeaderboardQuery } from 'store/services/api'
import MonthSwitcher from './MonthSwitcher'
import MonthlyTable from './MonthlyTable'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'
import { Button, Box, Flex, Text, Center } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/slices/authSlice'


const MonthlyLeaderboard = ({ isBlurred }) => {
  const [currentDate, setCurrentDate] = useState(dayjs().tz('America/New_York'))
  const user = useSelector(selectUser)
  const { data, isFetching } = useMonthlyLeaderboardListQuery(currentDate)
  const { data: mySet } = useUserMonthlyLeaderboardQuery(currentDate, { skip: !user })
  const { data: prizes, isLoading: isPrizesLoading } = useMonthlyPrizesQuery()
  const monthNumber = useMemo(() => currentDate.month() + 1, [currentDate])
  const isLoading = isFetching || isPrizesLoading
  const prize = prizes  && prizes.find(p => p.month === monthNumber)

  
  return (
    <Box>
      {isLoading || !data ? <Preloader /> : (
        <>
          <MonthSwitcher
            date={currentDate}
            countUsers={data.count_users}
            onPrev={() => {
              setCurrentDate(dayjs(currentDate).subtract(1, 'month'))
            }}
            onNext={() => {
              setCurrentDate(dayjs(currentDate).add(1, 'month'))
            }}
          />
          {prize && (
            <Box mt="20px">
              <Prize prize={prize} mininal />
            </Box>
          )}
          <Box
            mt="30px"
            pos="relative"
            userSelect="none"
          >
            <MonthlyTable
              sets={data.all_user_sets}
              mySet={mySet}
            />
            {isBlurred && (
              <Flex
                pos="absolute" 
                top="-20px"
                left="-20px"
                right="-20px"
                bottom="-20px"
                backdropFilter="blur(10px)"
                align="center"
                justify="center"
              >
                <Box>
                  <Text 
                    align="center"
                    fontWeight="700"
                    fontSize="18px"
                    lineHeight="22px"
                  >
                    Fill in your profile<br />to check the leaderboard 
                  </Text>
                  <Center>
                    <Button
                      variant="blue"
                      as={Link}
                      to={user ? routes.settings : routes.signin}
                      mt="20px"
                    >Jump to Profile</Button>
                  </Center>
                </Box>
              </Flex>
            )}
          </Box>
        </>
      )}
    </Box>
  )
};

export default MonthlyLeaderboard



