import { Box, Button, Center, Flex, Input, Text, Img } from '@chakra-ui/react';
import Preloader from 'components/Preloader';
import GameCard from 'components/game/GameCard';
import Prize from 'components/prize/Prize';
import routes from 'constants/routes';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useWeeklyPrizesQuery } from 'store/services/api';
import { useGetUserSetQuery, useLazyGetUserProfileQuery, useSendEmailMutation } from 'store/services/users';
import { selectUser, setUser } from 'store/slices/authSlice';
import { getDayNumber } from 'utils';
import { validateEmail } from 'utils/validation';
import SmsConfirmationForm from 'components/SmsConfirmationForm';
import { HIDE_SMS_CONFIRMATION_FORM } from 'constants/defaults';
import { logError } from 'utils/logger';
import { useGameQuery } from 'store/services/api';

const ResultPage = () => {
  const user = useSelector(selectUser);
  const [getMe] = useLazyGetUserProfileQuery();
  const dispatch = useDispatch();
  const [emailSent, setEmailSent] = useState(false);
  const { data: sets, isLoading } = useGetUserSetQuery({}, { skip: !user });
  const { data: weeklyPrizes, isLoading: isPrizesLoading } = useWeeklyPrizesQuery();
  const [displaySMSForm, setDisplaySMSForm] = useState(!localStorage.getItem(HIDE_SMS_CONFIRMATION_FORM));
  const { data: game, isError: isGameLoadimgError } = useGameQuery();

  const dayOfTheWeek = useMemo(getDayNumber, []);

  const [sendEmail, sendEmailResult] = useSendEmailMutation();

  const [email, setEmail] = useState('');

  const [promoImage, setPromoImage] = useState('');

  useEffect(() => {
    setEmail(user?.email || '');
  }, [user]);

  useEffect(() => {
    setPromoImage('');
    if (game?.promo_image) {
      const img = new Image();
      img.src = game.promo_image;

      img.onload = () => {
        setPromoImage(game.promo_image);
      };

      img.onerror = err => {
        logError('Image not found', {
          src: game.promo_image,
          errorMessage: err && (err.message || err),
          game: game.id,
        });
      };

      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }
  }, [game]);

  if (isLoading || isPrizesLoading) return <Preloader />;

  const selected = sets?.selected_players?.filter(s => s.is_selected) || [];

  if (!user || selected.length === 0) return <Navigate to={routes.home} />;

  const isValidEmail = validateEmail(email);

  const todayPrize = (weeklyPrizes || []).find(p => p.day_of_week === dayOfTheWeek);

  return (
    <Box>
      <Text fontWeight="800" fontSize="32px" align="center" color="#78BEFF">
        You’re set!
      </Text>
      <Text
        color="var(--chakra-colors-white80)"
        fontWeight="700"
        fontSize="18px"
        mt="10px"
        align="center"
        lineHeight="1.2"
      >
        Your hand for today is locked 🔒
      </Text>

      <Flex mt="30px" justifyContent="center" flexWrap="wrap" gap="11px">
        {selected.map(player => (
          <GameCard placed flipped key={player.rapid_id} player={player} selected />
        ))}
      </Flex>

      <Center mt="30px" gap="10px">
        <Button variant="blue" as={Link} to={routes.referralSetting}>
          GET SWAPS
        </Button>
        <Button as={Link} to={routes.leaderboard}>
          Leaderboard
        </Button>
      </Center>

      <Box mt="50px">
        {!isGameLoadimgError && game && (
          <Flex flexDirection="column" alignItems="center" gap="24px" mb="50px">
            <Text color="#FFF" fontWeight="700" fontSize="18px" align="center" lineHeight="1.2">
              {game.promo_title}
            </Text>
            <Link to={game.promo_link} target="_blank" style={{ width: '100%' }}>
              <Img lazyload width="100%" height="auto" src={promoImage} />
            </Link>
          </Flex>
        )}
        {user.email || emailSent ? (
          <>
            {displaySMSForm ? (
              <SmsConfirmationForm
                onSubmit={() => setDisplaySMSForm(!localStorage.getItem(HIDE_SMS_CONFIRMATION_FORM))}
              />
            ) : (
              <Flex
                alignItems="center"
                justifyContent="center"
                fontSize="16px"
                fontWeight="700"
                color="white"
                height="130px"
                p="10px"
                borderRadius="12px"
                bg="var(--chakra-colors-white5)"
                textAlign="center"
                flexWrap="wrap"
              >
                Results will be sent to {user.email || email}
              </Flex>
            )}
          </>
        ) : (
          <>
            <Text align="center" color="white" fontSize="24px" fontWeight="800">
              Get results via email
            </Text>
            <Input
              mt="20px"
              type="email"
              value={email}
              isDisabled={sendEmailResult.isRequesting || sendEmailResult.isLoading}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter a valid email address"
              outline="none"
              color="var(--chakra-colors-dark)"
              border="none"
              height="50px"
              bg="white"
              fontSize="16px"
              borderRadius="15px"
              fontWeight="700"
              _focus={{
                outline: 'none',
              }}
              _focusVisible={{
                outline: 'none',
              }}
              _placeholder={{
                color: 'var(--chakra-colors-dark60)',
              }}
            />
            <Center mt="20px">
              <Button
                variant="blue"
                isDisabled={sendEmailResult.isLoading || sendEmailResult.isRequesting || !isValidEmail}
                onClick={async e => {
                  e.preventDefault();
                  try {
                    const { error } = await sendEmail(email);
                    if (error) {
                      logError(error, {
                        component: 'ResultPage',
                        place: 'sendEmail',
                      });
                    }
                    setEmailSent(true);
                    const { data: user } = await getMe();
                    dispatch(setUser(user));
                  } catch (error) {
                    logError(error, {
                      component: 'ResultPage',
                      place: 'Notify me onClick',
                    });
                  }
                }}
              >
                Notify me
              </Button>
            </Center>
          </>
        )}
      </Box>

      <Box mt="50px">
        <Text fontSize="24px" fontWeight="800" align="center">
          Tonight’s prize
        </Text>
      </Box>
      <Box mt="20px">{todayPrize && <Prize mininal prize={todayPrize} />}</Box>
    </Box>
  );
};

export default ResultPage;
