import { Box } from '@chakra-ui/react'
import routes from 'constants/routes'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { selectUser } from 'store/slices/authSlice'


const links = [
  {
    path: routes.rules,
    name: 'rules'
  },
  {
    path: routes.prizes,
    name: 'prizes'
  },
  {
    path: routes.leaderboard,
    name: 'leaderboard'
  },
  {
    path: routes.signin,
    name: 'sign in',
    auth: true
  }
]

const Footer = () => {
  const user = useSelector(selectUser)
  const { pathname } = useLocation()
  
  return (
    <Box 
      px="16px"
      pb="16px"
    >
      {links.filter(link => !user || !link.auth).map(({ name, path, auth }) => (
        <Link
          key={name}
          onClick={() => window.scrollTo(0, 0)}
          style={{
            background: "var(--chakra-colors-white5)",
            color: "var(--chakra-colors-white40)",
            textTransform: "uppercase",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '35px',
            padding: "0 28px"
          }}
          to={auth ? `${path}?redirect=${pathname}` : path}
        >{name}</Link>
      ))}
      
    </Box>
  )
}

export default Footer