import routes from 'constants/routes'
import { useSelector } from 'react-redux'
import { Navigate, useParams } from 'react-router'
import { selectUser } from 'store/slices/authSlice'

const RefferalPage = () => {
  const user = useSelector(selectUser)
  const { id } = useParams()
  
  
  if (user) {
    return <Navigate to="/" />
  }
  return <Navigate to={`${routes.signin}?referral=${id}`} />
}

export default RefferalPage