import { Box, List, ListItem, Text } from '@chakra-ui/react';
import Preloader from 'components/Preloader';
import React from 'react';
import { useRulesQuery } from 'store/services/api';


const RulesPage = () => {
  const { data, isLoading } = useRulesQuery()

  if (isLoading || !data) return <Preloader />
  
  const rules = data.text_rules
  
  return (
    <Box>
      <Text
        color="#78BEFF"
        fontWeight="800"
        fontSize="32px"
        align="center"
      >
        Game Rules
      </Text>
      <Box>
        {Object.keys(rules).map(key => (
          <Box key={key} mt="30px">
            <Text
              fontSize="18px"
              fontWeight="700"
              opacity="0.8"
              color="var(--chakra-colors-blue)"
              align="center"
              textTransform="uppercase"
            >{key.split('_').join(' ')}</Text>
            <List 
              listStyleType="disc"
              paddingLeft="20px"
              mt="20px"
              fontSize="16px"
              fontWeight="700"
            >
              {rules[key].map((rule, index) => {
                if (typeof rule !== 'string') {
                  return null
                }
                return (
                  <ListItem 
                    key={rule}
                    mt={index > 0 ? '1em' : '0'}
                  >
                    <Box sx={{ '& a': { color: 'var(--chakra-colors-blue)' }}} dangerouslySetInnerHTML={{ __html: rule }} />
                  </ListItem>
                )
            })}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default RulesPage