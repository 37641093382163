import { Box, Flex, Grid } from '@chakra-ui/react'
import { useGetSubscriptionsQuery } from 'store/services/api'
import { useGetCurrentSubscriptionQuery, useLazyGetManageSubscriptionLinkQuery, useLazyGetPaymentLinkQuery } from 'store/services/users'


const UserSubsctiption = () => {
  const { data, isLoading } = useGetCurrentSubscriptionQuery()
  
  if (isLoading) return null

  return (
    <Box mt="50px">
      {data?.stripe_status ? <CurrentSubsctiption subscription={data.subscription_type} /> : <SubsctiptionsTable />}
    </Box>
  )
}


export default UserSubsctiption


const SubsctiptionsTable = () => {
  const { data: subscriptions, isLoading } = useGetSubscriptionsQuery()
  const [getPaymentLink, { isLoading: isGetPaymentLingLoading  }] = useLazyGetPaymentLinkQuery()

  const getLink = async id => {
    if (isGetPaymentLingLoading) return
    const response = await getPaymentLink(id)
    if (response.data.url) {
      window.location.href = response.data.url
    }
  }
  
  if (isLoading) return null
  
  return (
    <Box>
      <Box
        fontSize="20px"
        lineHeight="24px"
        fontWeight="800"
        textAlign="center"
        color="white"
      >Subscribe<br />to get swaps everyday</Box>
    
      <Grid 
        templateColumns="repeat(2, 1fr)" 
        columnGap="1px"
        fontSize="16px"
        fontWeight="700"
        mt="20px"
      >
        {subscriptions.map(s => (
          <Flex 
            key={`name${s.id}`}
            h="36px" 
            bg="var(--chakra-colors-blue)"
            color="white"
            alignItems="center"
            justifyContent="center"
            as="span"
            cursor="pointer"
            onClick={e => {
              e.preventDefault()
              getLink(s.id)
            }}
          >{s.product_name}</Flex>
        ))}
        
        {subscriptions.map(s => (
          <Flex 
            pos="relative"
            key={s.id}
            h="38px" 
            bg="#FFFFFF0F"
            color="white"
            alignItems="center"
            justifyContent="center"
            onClick={e => {
              e.preventDefault()
              getLink(s.id)
            }}
          >${s.unit_amount}
            {s.product_description && (
              <Box
                pos="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                bottom="-16px"
                left="50%"
                transform="translateX(-50%)"
                w="auto"
                h="22px"
                fontSize="10px"
                fontWeight="700"
                bg="#78BEFF"
                color="#161F28"
                px="4px"
              >  
                {s.product_description}
              </Box>
            )}
          </Flex>
        ))}
      </Grid>
    </Box>
  )
}

const CurrentSubsctiption = ({ subscription }) => {
  const [getLink, { isLoading }] = useLazyGetManageSubscriptionLinkQuery()
  return (
    <Flex 
      h="165px" 
      bg="#FFFFFF08"
      borderRadius="10px"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        fontSize="14px"
        fontWeight="600"
        lineHeight="20px"
        textAlign="center"
        opacity="0.8"
      >
        <Box color="white">
          Your current plan: {subscription}<br />Thanks for supporting 5 Card Draw!
        </Box>
        <Box mt="5px">
          <Box
            as="span"
            color="var(--chakra-colors-blue)"
            cursor="pointer"
            onClick={async e => {
              if (isLoading) return
              e.preventDefault()
              const response = await getLink()
              if (response.data.url) {
                window.location.href = response.data.url
              }
            }}
          >{"Manage subscription >"}</Box>
        </Box>
      </Box>
    </Flex>
  )
}