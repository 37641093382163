import { Flex } from '@chakra-ui/layout'

const Switcher = ({ index, onChange }) => {
  return (
    <Flex
    position="relative"
    bg="var(--chakra-colors-white10)"
    alignItems="stretch"
    height="37px"
    borderRadius="32px"
    mt="15px"
    w="270px"
    mx="auto"
  >
    {/* <Box 
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius="50%"
      zIndex="100"
    /> */}
    <Flex
      onClick={() => onChange(0)}
      flex="1"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bg={index === 0 ? 'var(--chakra-colors-white40)' : 'transparent'}
      cursor="pointer"
    >
      DAILY
    </Flex>
      
    <Flex 
      flex="1"
      onClick={() => onChange(1)}
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bg={index === 1 ? 'var(--chakra-colors-white40)' : 'transparent'}
      cursor="pointer"
    >
      MONTHLY
    </Flex>
  </Flex>
  )
}

export default Switcher
