import { Flex, Text } from '@chakra-ui/layout'
import dayjs from 'dayjs';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg'
import { ReactComponent as RightArrow } from 'icons/right-arrow.svg'
import { useMemo } from 'react';

function checkIsCurrentMonth(date) {
  const now = dayjs();
  return date.month() === now.month() && date.year() === now.year();
}

function checkHasPrevious(date) {
  return date.year() > 2024 || (date.year() === 2024 && date.month() > 0)
}

const MonthSwitcher = ({ countUsers, date, onPrev, onNext }) => {
  const isCurrentMonth = useMemo(() => checkIsCurrentMonth(date), [date])
  const hasPrevious = useMemo(() => checkHasPrevious(date), [date])
  const formattedUserCount = useMemo(
    () =>  new Intl.NumberFormat('en-EN', { maximumSignificantDigits: 3 }).format(
      countUsers,
    ),
    [countUsers]
  )

  return (
    <Flex
      py="8px"
      alignItems="center"
      justifyContent="center"
      gap="25px"
    >
      <LeftArrow 
        onClick={hasPrevious ? onPrev : undefined}
        style={{
          cursor: !hasPrevious ? 'default' : 'pointer',
          opacity: !hasPrevious ? '0.2' : '1'
        }}
        
      />
      <Flex
        color="var(--chakra-colors-white80)"
        fontSize="18px"
        fontWeight="700"
        alignItems="center"
        gap="10px"
        justifyContent="center"
        width="275px"
        whiteSpace="nowrap"
      >
        {countUsers === undefined ? (
          <>
            <Text>{date ? date.format('MMMM YYYY') : ""}</Text>
          </>
        ) : (
          <>
            <Text>{date ? date.format('MMMM YYYY') : ""}</Text>
            <Text>·</Text>
            <Text>{formattedUserCount} players</Text>
          </>
        )}
      </Flex>
      <RightArrow
        onClick={isCurrentMonth ? undefined : onNext}
        style={{
          cursor: isCurrentMonth ? 'default' : 'pointer',
          opacity: isCurrentMonth ? '0.2' : '1'
        }}
      />
    </Flex>
  )
}

export default MonthSwitcher
