// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  --chakra-fonts-body: 'Manrope', sans-serif;
  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg); 
  --chakra-colors-chakra-body-text: var(--chakra-colors-white);
  --base-font-size: 16px;
  /* overscroll-behavior-y: contain; */
}

#root {
  max-width: 400px;
  margin: 0 auto;
  
}

.open-menu {
  overflow: hidden;
  background: black;
}


/* @keyframes slideUp {
  from {
    transform: translate(0, 90px);
  }
  to {
    transform: translate(0, 0);
  }
} */


@keyframes flashanim {
  0% {	color: var(--chakra-colors-white80);}   
  100% {	color: #1E93FF;}
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAEA;EACE,0CAA0C;EAC1C,uDAAuD;EACvD,4DAA4D;EAC5D,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,cAAc;;AAEhB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;;AAGA;;;;;;;GAOG;;;AAGH;EACE,KAAK,mCAAmC,CAAC;EACzC,OAAO,cAAc,CAAC;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');\n\nbody {\n  --chakra-fonts-body: 'Manrope', sans-serif;\n  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg); \n  --chakra-colors-chakra-body-text: var(--chakra-colors-white);\n  --base-font-size: 16px;\n  /* overscroll-behavior-y: contain; */\n}\n\n#root {\n  max-width: 400px;\n  margin: 0 auto;\n  \n}\n\n.open-menu {\n  overflow: hidden;\n  background: black;\n}\n\n\n/* @keyframes slideUp {\n  from {\n    transform: translate(0, 90px);\n  }\n  to {\n    transform: translate(0, 0);\n  }\n} */\n\n\n@keyframes flashanim {\n  0% {\tcolor: var(--chakra-colors-white80);}   \n  100% {\tcolor: #1E93FF;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
