import axios from 'axios'
import { use } from 'marked'
import { createApi } from '@reduxjs/toolkit/query/react'
import { renderer } from '../../utils'
import { selectCardIds } from '../slices/gameSlice'

use({ renderer });

const isDevMode = window.location.href.startsWith('https://test.5card.co') || window.location.href.startsWith('http://localhost')

const baseURL = isDevMode ? 'https://api.test.5card.co' : 'https://api.5card.co'

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 5000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});


const baseQuery = async ({ body, ...args }, { signal, getState }) => {

  try {
    const result = await axiosInstance({ data: body, signal, ...args })
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError;
    
    return {
      error: {
        status: err?.response?.status || 0,
        data: err?.response?.data || err || new Error('Something went wrong'),
      },
    };
  }
};

export const api = createApi({
  baseQuery,
  endpoints: build => ({
    getContent: build.query({
      query: () => {
        return {
          url: `api/v1/app_info/`,
        };
      },
    }),
    weeklyPrizes: build.query({
      query: () => {
        return {
          url: `api/v1/weekly_prizes/`,

        };
      },
      transformResponse: (records) => {
        const data = records || []
        data.sort((a, b) => a.date.localeCompare(b.date))
        return data
      },
    }),
    monthlyPrizes: build.query({
      query: () => {
        return {
          url: `api/v1/monthly_prizes/`,

        };
      },
    }),
    claimedPrizes: build.query({
      query: () => {
        return {
          url: `api/v1/claimed_prizes/`,
        };
      },
      transformResponse: (records) => {
        const data = records.map(r => ({ ...r.prize, email: r.email, date: r.date_of_winning }))
        data.sort((a, b) => b.date.localeCompare(a.date))
        return data
      },
    }),
    leaderboardList: build.query({
      query: (date) => {
        return {
          url: `api/v1/leaderboard/?date=${date.format('YYYY-MM-DD')}`,
        };
      },
    }),
    monthlyLeaderboardList: build.query({
      query: (date) => {
        return {
          url: `api/v1/monthly_leaderboard/?date=${date.format('YYYY-MM')}-01`,
        };
      },
    }),
    userMonthlyLeaderboard: build.query({
      query: (date) => {
        return {
          url: `api/v1/userprofile/leaderboard?date=${date.format('YYYY-MM')}-01`,
        };
      },
    }),
    game: build.query({
      query: () => {
        return {
          url: 'api/v1/game_settings/',
        };
      },
    }),
    rules: build.query({
      query: () => {
        return {
          url: 'api/v1/game_rules/',
        };
      },
    }),
    getSubscriptions: build.query({
      query: () => {
        return {
          url: 'get_products/',
        };
      },
    }),
    playersList: build.query({
      query: (type) => {
        console.log(type)
        return {
          url: `api/v1/players/${type}/`,
        };
      },
    }),
  }),
});


export const {
  useClaimedPrizesQuery,
  useGetContentQuery,
  useLazyClaimedPrizesQuery,
  useLazyGetContentQuery,
  useLazyLeaderboardListQuery,
  useLazyWeeklyPrizesQuery,
  useLeaderboardListQuery,
  useWeeklyPrizesQuery,
  useMonthlyPrizesQuery,
  useMonthlyLeaderboardListQuery,
  useUserMonthlyLeaderboardQuery,
  useRulesQuery,
  useGameQuery,
  useLazyGameQuery,
  useGetSubscriptionsQuery,
  usePlayersListQuery,
} = api;