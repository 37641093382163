import { Box, Button, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as SubscribedIcon } from 'icons/subscribed.svg'

const MothlyTable = ({ sets, mySet }) => {

  const myIndex = mySet ? sets.findIndex(s => mySet?.username && (s?.username === mySet?.username)) : -1
  const inTop = myIndex !== -1

  const [showMore, setShowMore] = useState(false)

  useEffect(
    () => {
      setShowMore(false)
    },
    [sets]
  )

  const list = showMore ? sets : sets.slice(0, 15)

  return (
    <Box minH="43px">
      {(!inTop || myIndex >= 15) && Object.keys(mySet || {}).length > 0 && (
        <Box 
          mb="20px"
          borderRadius="8px"
          overflow="hidden"
        >
          <Row set={mySet} hightlighted /> 
        </Box>
      )}
      <Box
        border="1px solid #2D353D"
        borderRadius="8px"
        overflow="hidden"
      >
        {list.map(set => 
          <Row 
            key={set.id} 
            set={set} 
            hightlighted={mySet && mySet.user_id === set.user_id}
          />)}
      </Box>
      {(!showMore && sets?.length > 15 ) && (
        <Box mt="20px" textAlign="center">
          <Button
            variant="blue"
            onClick={e => {
              setShowMore(true)
            }}
          >Show More</Button>
        </Box>
      )}
    </Box>
  )
}

export default MothlyTable


const Row = ({ set, hightlighted }) => (
  <Link to={`/user/${set.user_id}`}>
    <Flex
      fontSize="13px"
      fontWeight="600"
      letterSpacing="0.02em"
      height="42px"
      alignItems="center"
      px="10px"
      color={set.is_first && !hightlighted ? '#EEA500' : 'whte'}
      sx={hightlighted ? {
        bg: '#1E93FF'
      } : {
        ':nth-of-type(odd)': {
          bg: '#222A33'
        }
      }}
    >
      <Box
        flexBasis="60px"
        flexShrink="0"
      >{set.monthly_place}{set.is_first && <> 🏆</>}</Box>
      <Box
        flexBasis="100%"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        display="flex"
        alignItems="center"
        gap="5px"
      >
        <Flex alignItems="center" gap="2px">
          {set.username}
          {set.subscribed && <SubscribedIcon width="20px" height="20px" color="#0059AC" />}
        </Flex>
        {!!set.streak && (
          <Box
            borderRadius="14px"
            color="white"
            bg="#78BEFF"
            fontSize="11px"
            px="7.5px"
            pt="2px"
            pb="1px"
          >
            {set.streak}
          </Box>
        )}
      </Box>
      {set.last_game_points ? (
        <Box
          textAlign="right"
          flexShrink="0"
          color="#90EE90"
          marginRight="5px"
        >+{set.last_game_points.toLocaleString('en-US')}</Box> 
      ) : null}
      <Box
        flexBasis="40px"
        textAlign="right"
        flexShrink="0"
      >{set.monthly_total_points.toLocaleString('en-US')}</Box>
    </Flex>
  </Link>
)