import { extendTheme } from '@chakra-ui/react'

const colors = {
  blue: '#1E93FF',
  red: '#DD5E5E',
  yellow: '#EEA500',
  bg: '#161F28',
  dark: '#141414',
  dark60: 'rgba(20, 20, 20, 0.60)',
  dark90: 'rgba(20, 20, 20, 0.90)',
  
  white: '#FFFFFF',
  white5: 'rgba(255, 255, 255, 0.05)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white80: 'rgba(255, 255, 255, 0.8)'
}


const theme = extendTheme({ 
  colors,
  components: {
    Text: {
      baseStyle: {},
      sizes: {
        default: {

        },
        title: {
          fontSize: '32px',
          fontWeight: '800'
        },
        text: {
          fontSize: '16px',
          fontWeight: '700',
          lineHeight: '22px',
          color: 'var(--chakra-colors-white80)',
          '& a': {
            color: '#1E93FF'
          }
        }
      },
      defaultProps: {
        size: 'default',
      }
    },
    Button: {
      baseStyle: {
        fontSize: '18px',
        fontWeight: '700',
        borderRadius: '32px',
        color: 'white',
        textAlign: 'center',
        _focus: {
          boxShadow: 'none'
        }
      },
      sizes: {
        sm: {
          height: '40px',
          fontSize: '14px',
          fontWeight: '600',
        },
        md: {
          width: '200px',
          height: '60px',
        },
      },
      variants: {
        blue: {
          background: 'var(--chakra-colors-blue)',
          _disabled: {
            background: 'var(--chakra-colors-white5) !important',
          }
        },
        grey: {
          background: 'rgba(255, 255, 255, 0.05)'
        }
      },
      defaultProps: {
        size: 'md',
        variant: 'grey'
      }
    }
  }
})

export default theme