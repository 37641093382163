import { Box, Flex, Image } from '@chakra-ui/react';

const PromoSponsor = ({ prize }) => {
  if (!prize) {
    return null;
  }

  return (
    <Flex alignItems="center" padding="12px" position="absolute" bottom={0} right={0}>
      <Box
        marginRight="-4px"
        py="4px"
        px="8px"
        fontSize="12px"
        fontWeight="800"
        backgroundColor="#fff"
        color="#2601e6"
        borderRadius="8px 0 0 8px"
        boxShadow="0 10px 10px -1px rgba(0,0,0,0.1)"
        lineHeight={1.2}
      >
        ${prize.price} Only on
      </Box>
      <Box width="80px" aspectRatio="1.75/1" borderRadius="50%" boxShadow="0 10px 20px -1px rgba(0,0,0,0.3)">
        <Image src="/images/KICKSCREW-logo-white_256x.png" />
      </Box>
    </Flex>
  );
};

export default PromoSponsor;
