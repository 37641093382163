import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { numberSuffix } from 'utils'
import GameCard from './game/GameCard'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/slices/authSlice'
import { ReactComponent as SubscribedIcon } from 'icons/subscribed.svg'



const WinnersTable = ({ winners }) => {
  const [showMore, setShowMore] = useState(false)
  const user = useSelector(selectUser)
  const userId = user? user.id : null

  useEffect(
    () => {
      setShowMore(false)
    },
    [winners]
  )

  if (!winners.some(winner => winner.total_points > 0)) return null
  const list = showMore ? winners : winners.slice(0, 3)

  
  return (
    <Box mt="30px">
      {list.map((winner, index) => {
        const isFirst = winner.is_first
        return (
          <Flex
            key={winner.id}
            justifyContent="space-between"
            alignItems="stretch"
            p="10px 15px"
            borderRadius="14px"
            bg={isFirst ? "var(--chakra-colors-white5)" : "transparent"}
            border={isFirst ? "1px solid var(--chakra-colors-yellow)" : "1px solid var(--chakra-colors-white10)"}
            color={isFirst ? "var(--chakra-colors-yellow)" : "white"} 
            mt={index > 0 ? "20px" : "0px"}
            as={Link}
            to={`/user/${winner.user_id}`}
          >
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              maxWidth="calc(100% - 188px)"
            >            
              <Box>
                {userId === winner.user_id ? (
                  <Text
                    textTransform="uppercase"
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="17px"
                    color={isFirst ? "var(--chakra-colors-yellow)" : "var(--chakra-colors-white40)"}
                  >you ({numberSuffix(winner.place)})</Text>
                ) : (
                  <Text
                    textTransform="uppercase"
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="17px"
                    color={isFirst ? "var(--chakra-colors-yellow)" : "var(--chakra-colors-white40)"}
                  >{numberSuffix(winner.place)}{isFirst && <> 🏆</>}</Text>
                )}
                  
                <Text
                  lineHeight="26px"
                  fontSize="20px"
                  fontWeight="800"
                >
                  {winner.total_points} pts
                </Text>
              </Box>
              <Flex gap="2px" alignItems="center">
                {winner.subscribed && (
                  <SubscribedIcon width="20px" height="20px" color="#0059AC" />
                )}
                <Box
                  fontSize="13px"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >{winner.user_username}</Box>
              </Flex>
              
            </Flex>
            
            <Flex gap="8px">
              {winner.selected_players.filter(p => p.is_selected).map(p => (
                <Box key={p.rapid_id}>
                  <GameCard
                    placed
                    flipped
                    player={p}
                    selected
                    small
                  />
                  <Box 
                    fontWeight="600" 
                    fontSize="12px" 
                    mt="6px" 
                    lineHeight="12px" 
                    textAlign="center"
                    color="white"
                  >{p.points}</Box>
                </Box>
              ))}
            </Flex>
            
          </Flex>
        )}
      )}
      {(!showMore && winners?.length > 3) && (
        <Box mt="20px" textAlign="center">
          <Button
            variant="blue"
            onClick={e => {
              setShowMore(true)
            }}
          >Show More</Button>
        </Box>
      )}
    </Box>
  )
}

export default WinnersTable