import { configureStore } from '@reduxjs/toolkit'
import { api, axiosInstance } from './services/api'
import auth from './slices/authSlice'
import game from './slices/gameSlice'
import {  getAccessToken } from 'storage/auth';

export const setRequestInterceptors = () => {
  axiosInstance.interceptors.request.use(
    async config => {
      config.headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      const token = getAccessToken()
      if (token) {
        config.headers['Authorization'] = `Token ${token}`
      }
      return config;
    },
    error => {
      Promise.reject(error || new Error("Request Error"))
  });
}

setRequestInterceptors()

export const createStore = options =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      auth,
      game,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(api.middleware),
    ...options,
  });

export const store = createStore();
