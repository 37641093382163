import { Box, List, ListItem, Text } from '@chakra-ui/react'

const PrivacyPolicyPage = () => {  
  return (
    <Box>
      <Text
        color="#78BEFF"
        fontWeight="800"
        fontSize="32px"
        align="center"
      >
        Privacy Policy
      </Text>
      <Box mt="35px">

        <Text size="text">
          Welcome to 5 Card Draw! Rhino Studios Inc. is the owner of 5CARD.co (“5 CARD,” “we” and “us”) and we care about your privacy.  We have developed this Privacy Policy (this “Policy”) to describe the information we collect, how that information may be used, with whom it may be shared, and your choices, in connection with your use of our website, our contests, drawings, and sweepstakes (collectively, “Games”), and any of our products and services that link to this Policy (collectively, our “Services.”). 
          <br />
          <br />
          Please read this Policy carefully before using our Services.
          <br />
          <br />
          INFORMATION WE COLLECT
          <br />
          <br />
          We collect information in a few ways, so we have broken things down for you below to explain the types of information that we collect in each context.
          <br />
          <br />
          Information You Provide to Us
          <br />
          <br />
          <List
            listStyleType="disc"
            paddingLeft="1em"
            listStylePosition="outside"
          >
          
          <ListItem>
            Your Account Information: <br />
            When you use our Services, you may provide certain information directly to us.  For example, when you register for an account with 5 CARD DRAW or play our Games, we require that you provide us with your name, a valid phone number, mailing address, and email address.  You may also create a username.  Once you create an account, we collect any additional information that you provide to your account or provide to us through your account.  When you play our Games, we collect your activity in the Games, such as your entries and your entry history and your referrals. 
            <br /><br />
          </ListItem>
          <ListItem>
            Your Communication with Us:<br />
            We collect information when you communicate with us. The specific types of information we collect will depend on the forum in which you communicate with us.  For example, if you send us an email, we will collect your email address and the content of your email.  
            <br /><br />
          </ListItem>
          <ListItem>
            Referrals:<br />
            Players of our Games may create a unique referral link to invite others to join and play. If you create an account or sign up to play our Games by clicking a referral link, we collect information from you through your account creation or Game signup process, and we may associate the referral creator with accounts created through that creator’s referral link in our systems. 
          </ListItem>
          </List>
          
          <br />
          <br />
          Information We Automatically Collect
          <br />
          <br />
          As with most websites, when you use our Services we automatically receive and collect information about you and your device.  This information includes the following:
          <br />
          <br />
          <List
            listStyleType="disc"
            paddingLeft="1em"
            listStylePosition="outside"
          >
            <ListItem>
              Information about your device, such as the operating system, hardware, system version, the Internet Protocol (IP) address, device ID, and device language.
              <br />
            </ListItem>
            <ListItem>
              The specific actions that you take when you use our Services, including but not limited to the pages and screens that you view or visit, search terms that you enter, how you interact with our Services, and information about your interactions with the Services.
              <br />
            </ListItem>
            <ListItem>
              The time, frequency, connection type, and duration of your use of our Services.
              <br />
            </ListItem>
            <ListItem>
              Location information, such as GPS information. 
              <br />
            </ListItem>
            <ListItem>
              Information regarding your interaction with email messages, for example, whether you opened, clicked on, or forwarded the email message.
              <br />
            </ListItem>
            <ListItem>
              Identifiers associated with cookies or other technologies that may uniquely identify your device or browser (as further described below); and
              <br />
            </ListItem>
            <ListItem>
              Pages you visited before or after navigating to our website.	
              <br />
            </ListItem>
          </List>
          <br />
          <br />
          Cookies and Analytics
          <br />
          <br />
          As most websites do, we use analytics partners to collect certain information about how our Services are used. We and our analytics partners use cookies, web beacons, and other technologies to receive and store certain types of information whenever you interact with our Services through your computer or mobile device. A cookie is a small file containing a string of characters that is sent to your computer when you visit a website. When you visit the website again, the cookie allows that site to recognize your browser. Cookies may store unique identifiers, user preferences and other information. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some website features or services may not function properly without cookies. We use cookies to analyze and improve our services, to store user preferences, track user trends, and provide relevant advertising to you. 
          <br />
          <br />
          We may use third parties, such as Google Analytics or other analytics providers, to analyze traffic to our website. Google Analytics does not create individual profiles for visitors and only collects aggregate data. To disable Google Analytics, please download the browser add-on for the deactivation of Google Analytics provided by Google at http://tools.google.com/dlpage/gaoptout?hl=en. To learn more about privacy and Google Analytics, please consult the Google Analytics overview provided by Google at http://www.google.com/intl/en/analytics/privacyoverview.html. 
          <br />
          <br />
          We may also use cookies, pixels, beacons, or other web tracking technologies to track the amount of time spent on our Services and whether or not certain content was viewed. We may work with a third party to collect and process this information for us, based on our instructions and in compliance with this Policy.
          <br />
          <br />
          At the present time our Services do not respond to “Do Not Track” signals or similar mechanisms.
          <br />
          <br />
          Interest-Based Advertising
          <br />
          <br />
          We participate in interest-based and behavioral advertising. We use third-party advertising partners to display ads tailored to your individual interests based on how you browse and shop online, as well as to provide advertising-related services such as ad delivery, reporting, attribution, analytics, and market research.
          <br />
          <br />
          We allow third-party companies to collect certain information when you visit our website. This information is used to serve ads for 5 Card products or services, or for the products or services of other companies when you visit our website or other websites. These companies use non-identifiable information (e.g., click stream information, browser type, time and date, subject of advertisements clicked or scrolled over, hardware/software information, and session ID) and personally identifiable information (e.g., static IP address) during your visits to this and other websites in order to provide advertisements about goods and services likely to be of greater interest to you. These parties typically use a cookie, web beacon or other similar tracking technologies to collect this information. 
          <br />
          <br />
          <br />
          HOW WE USE YOUR INFORMATION 
          <br />
          <br />
          In general, we collect information from you so that we can provide our Services, operate our business, and provide information that you request from us. This includes the following uses and purposes: 
          <br />
          <br />
          <List
            listStyleType="disc"
            paddingLeft="1em"
            listStylePosition="outside"
          >
            <ListItem>Create and administer your account.</ListItem>
            <ListItem>Facilitate our Games, including entry, winner selection, and awarding and fulfillment of prizes.</ListItem>
            <ListItem>Provide, operate, improve, maintain, and protect our Services.</ListItem>
            <ListItem>Provide you with technical and other support.</ListItem>
            <ListItem>Send you Services and company updates, marketing communication, service information, and other information about 5 CARD and our Services, and products and services of third parties, that we think you may be interested in.</ListItem>
            <ListItem>Conduct research and analysis, and monitor and analyze trends and usage.</ListItem>
            <ListItem>Enhance or improve user experience, our business, and our services, including the safety and security thereof.</ListItem>
            <ListItem>Personalize our services to you by, for example, customizing content that you see. </ListItem>
            <ListItem>Communicate with you and respond to inquiries.</ListItem>
            <ListItem>Operate our business and perform any other function that we believe in good faith is necessary to protect the security or proper functioning of our Services.</ListItem>
            <ListItem>As necessary to comply with any applicable law, regulation, subpoena, legal process, or governmental request.</ListItem>
            <ListItem>Enforce contracts and applicable Terms of Service and Games Official Rules, including investigation of potential violations thereof.</ListItem>
            <ListItem>Detect, prevent, or otherwise address fraud, security or technical issues.</ListItem>
            <ListItem>Protect against harm to the rights, property or safety of 5 CARD, our users, customers, or the public as required or permitted by law.</ListItem>
          </List>
          <br />
          HOW WE SHARE YOUR INFORMATION
          <br />
          <br />
          Like most companies, we share information in certain circumstances with third parties through operation of our Services and our business.  Below we explain when that happens. 
          <br />
          <br />
          Service Providers
          <br />
          We use third parties to assist us with operating our business and providing our Services, such as our technology vendors that help us maintain our Services and partners that assist us with our marketing and communication.  These service providers will have access to your information in order to provide services to us.
          <br />
          <br />
          Referrals
          <br />
          If you create an account in the Services or sign up to play our Games by clicking a referral link, we may notify the creator of the referral link to let he or she know that you created an account or signed up to play our Games, and may share your contact information with the creator including your name, phone number, email address, and date of account activation (or date of Game entry).   We are not responsible for the creator’s use of your information. 
          <br />
          <br />
          Other Players
          <br />
          Your username may be visible to other users in the Services.  For example, if you are on our Game leaderboard, your username will be displayed and visible to other users. 
          <br />
          <br />
          As Directed By You and With Your Consent
          <br />
          We share information with companies, organizations or individuals outside of 5 CARD at your direction or when we have your consent to do so.  
          <br />
          <br />
          Legal Proceedings
          <br />
          We may share information with third party companies, organizations, governmental authorities, or individuals outside of 5 CARD if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:
          ●	Meet any applicable law, regulation, subpoena, legal process or governmental request;
          ●	Enforce a contract, including but not limited to any applicable Terms of Service or Games Official Rules, including investigation of potential violations thereof;
          ●	Detect, prevent, or otherwise address fraud, security or technical issues; or
          ●	Protect against harm to the rights, property or safety of 5 CARD, our users, customers or the public as required or permitted by law.
          <br />
          <br />
          Sale or Merger<br />
          We may share information about you as part of a merger or acquisition. If Rhino Studios Inc. is involved in a merger, asset sale, financing, liquidation or bankruptcy, or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes. In such a case, unless permitted or otherwise directed by applicable law, your information would remain subject to the terms of the applicable privacy policy in effect at the time of such transfer.
          <br />
          <br />
          Aggregate Information<br />
          We may de-identify or aggregate information so that you are not identified as an individual, and use and provide that information to third parties without restriction. We may also provide aggregate usage information to third parties (or allow third parties to collect that information from you), who may use such information to understand how often and in what ways people use our Services. 
          <br />
          <br />
          CHOICES ABOUT YOUR INFORMATION
          <br />
          <br />
          We strive to provide you with choices with respect to your information.  Also remember, you can opt not to disclose certain information to us - but keep in mind some information may be needed to create a 5 CARD account, play our Games, or to take advantage of some of our Services and features.
          <br />
          <br />
          <br />
          Marketing <br />
          You may unsubscribe from 5 CARD marketing communications at any time by following the “unsubscribe” link at the bottom of any such communication. Most promotional communications will also offer recipients choices about receiving additional messages.
          <br />
          <br />
          Text Messages<br />
          We may send you text messages if you have opted-in to receive them. You can opt-out form receiving text messages at any time by texting "STOP". After you send "STOP", we may send you an additional text message to confirm that you have been unsubscribed. You will no longer receive text messages from that short code or number, as applicable, but you may receive text messages if you are subscribed to other text message lists, and you may continue to receive transactional text messages.  Message and data rates may apply.
          <br />
          <br />
          <br />
          CHILDREN
          <br />
          <br />
          Our Services are not directed at children under 13, and we do not knowingly collect information from children under 13. If you are under 13, please do not attempt to use our Services, play our Games, or send any information about yourself to us.  
          <br />
          <br />
          SECURITY OF YOUR INFORMATION
          <br />
          <br />
          We use reasonable security measures, including measures designed to protect against unauthorized or unlawful processing and against accidental loss, destruction or damage to your information.  We also take certain measures to enhance the security of our Services, however, since the Internet is not a 100% secure environment, we cannot guarantee, ensure, or warrant the security of any information you transmit to us. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information. 
          <br />
          <br />
          RETENTION OF INFORMATION
          <br />
          <br />
          We retain information in accordance with applicable laws. The length of time we keep information depends on the type of information and whether we have an on-going business or legal need to retain it (for example, if you have an account with us, or to comply with applicable legal, tax or accounting requirements). 
          <br />
          <br />
          THIRD PARTY WEBSITES AND SERVICES
          <br />
          <br />
          Our Services may contain links to other websites and services operated by third parties, and may include social media features such as Snapchat and Instagram buttons or links. These third-party websites and services may collect information about you if you click on a link or visit those websites or services, and the social media sites may automatically record information about your browsing behavior every time you visit a website that has a social media button. Your interactions with these features and third parties are governed by the privacy policy of the third party, not by this Policy.  
          <br />
          <br />
          <br />
          CHANGES TO THIS POLICY
          <br />
          <br />
          We may make changes to this Policy from time to time. When we do, we will post the updated version on this page. We encourage you to read this page each time that you use our Services so that you will be aware of any changes, and your continued use of our Services shall constitute your acceptance of any such changes. Changes to this Policy take effect from the date of publication, unless stated otherwise. 
          <br />
          <br />
          <br />
          CONTACT US
          <br />
          <br />
          If you have any comments, questions, concerns, or suggestions about Policy, or about our privacy practices in general, please contact us at info@5card.co
          <br />
          <br />
          <br />
        </Text>

      </Box>
    </Box>
  )
}

export default PrivacyPolicyPage


