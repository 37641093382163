import { Box, Flex, Text } from '@chakra-ui/layout'
import GameBoard from 'components/game/GameBoard'
import { Button, Center } from '@chakra-ui/react'
import { numberSuffix } from 'utils'
import WinnersTable from 'components/WinnersTable'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'


const Winners = ({ league, prize, userSets, otherSets, isToday }) => {
  const maxValue = Math.max(...otherSets.map(s => s.total_points))

  return (
    <Box>
      {userSets && (
        <>
          <GameBoard 
            disabled
            withPoints
            league={league}
            prize={prize}
            players={userSets.selected_players.filter(p => p.is_selected) || []}
          />
          <Flex
            justifyContent="center"
            alignItems="center"
            gap="10px"
            mt="20px"
            h="64px"
          >
            <Box 
              flexBasis="100%"
              h="10px"
              bg="linear-gradient(270deg, #1E93FF 0%, rgba(30, 147, 255, 0) 100%)"
              position="relative"
            >
              <Box
                position="absolute"
                w="0"
                h="0"
                top="50%"
                left={`${userSets.total_points / maxValue * 100}%`}
              >
                <Box
                  bg="#1D80DB"
                  w="16px"
                  h="16px"
                  border="4px solid white"
                  borderRadius="8px"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  position="absolute"
                >
                  <Box
                    fontSize="14px"
                    fontWeight="700"
                    position="absolute"
                    left="50%"
                    top="-25px"
                    lineHeight="1"
                    transform="translateX(-50%)"
                  >
                    {userSets.total_points}
                  </Box>
                  <Box
                    fontSize="12px"
                    fontWeight="700"
                    position="absolute"
                    left="50%"
                    bottom="-25px"
                    lineHeight="1"
                    transform="translateX(-50%)"
                    textTransform="uppercase"
                  >
                    {numberSuffix(userSets.place)}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Text
              flexShrink="0"
              fontSize="16px"
              fontWeight="600"
            >🏆</Text>
          </Flex>

          {isToday && (<Center mt="30px">
            <Button 
              variant="blue"
              as={Link}
              to={routes.referralSetting}
            >
              GET SWAPS
            </Button>
          </Center>)}
          <Text
            mt="25px"
            fontSize="14px"
            fontWeight="600"
            lineHeight="1.2"
            align="center"
            mb="-10px"
          >TOP HANDS</Text>
        </>
      )}
      <WinnersTable winners={otherSets} />
    </Box>
  )
}

export default Winners