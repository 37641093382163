import * as Sentry from '@sentry/react'

export function logError(error, extra = undefined) {
  const message = error?.error || error?.message || error?.originalError || error;
  console.error(message)
  if (typeof message !== 'string') {
    return
  }
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureMessage(message, {
      level: 'error',
      extra
    })
}
}