import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  selectedCardIds: [],
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resetGame(state) {
      state.selectedCardIds = [];
    },
    setSelectedCards(state, { payload }) {
      state.selectedCardIds = payload;
    },
  },
});

export default persistReducer(
  {
    key: 'rtk:game',
    storage,
    whitelist: ['dailyPlayersIds', 'boardCardIds', 'selectedCardIds'],
  },
  gameSlice.reducer,
);

export const { resetGame, setSelectedCards } = gameSlice.actions;

export const selectCardIds = state => state.game.selectedCardIds;
