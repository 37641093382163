import { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import Prize from 'components/prize/Prize'
import PrizesTabs from 'components/prize/PrizesTabs';
import { useClaimedPrizesQuery, useWeeklyPrizesQuery } from 'store/services/api';


const PrizesPage = () => {
  const [index, setIndex] = useState(0);
  const { data: weeklyPrizes } = useWeeklyPrizesQuery()
  const { data: claimedPrizes } = useClaimedPrizesQuery()
  const prizes = index === 0 ? weeklyPrizes : claimedPrizes
  
  return (
    <Box>
      <Text
        fontWeight="800"
        fontSize="32px"
        align="center"
        color="#78BEFF"
      >
        Prizes
      </Text>
      <PrizesTabs index={index} onChange={setIndex} />
      <Flex direction="column" gap="40px" mt="30px">
        {prizes && prizes.map((prize, i) => (
          <Prize claimed={index === 1} key={`${prize.day_of_week}_${index}_${i}`} prize={prize} />
        ))}
      </Flex>
    </Box>
  );
}

export default PrizesPage