import { Text } from '@chakra-ui/layout'

const EmptyMessage = ({ message }) => (
  <Text
    mt="30px"
    display="flex"
    alignItems="center"
    height="330px"
    p="15px"
    borderRadius="12px"
    bg="var(--chakra-colors-white5)"
    color="var(--chakra-colors-white80)"
    align="center"
    fontSize="16px"
    fontWeight="700"
    lineHeight="1.4"
  >
    {message || 'Your hand will appear here. Scores will be announced after all games have been completed'}
  </Text>
)

export default EmptyMessage