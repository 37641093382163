import { Box, Button, Center, Flex } from '@chakra-ui/react'
import GameCard from 'components/game/GameCard'
import { useCallback, useRef, useState } from 'react'
import { usePlayersListQuery } from 'store/services/api'
import * as htmlToImage from 'html-to-image';

const PlayersListPage = () => {  
  const [type, setType] = useState('nba')

  const { data: players, error, isLoading, isFetching, isError } = usePlayersListQuery(type)

  if (isLoading || isFetching) return null;
  if (isError) {
    console.error(error)
    return null;
  }

  const groupedByTeam = players.reduce((acc, player) => {
    const team = player.team_name;
    if (!acc[team]) {
        acc[team] = [];
    }
    acc[team].push(player);
    return acc;
  }, {});
  
  return (
    <Box>
      <Flex
        position="relative"
        bg="var(--chakra-colors-white10)"
        alignItems="stretch"
        height="37px"
        borderRadius="32px"
        mt="15px"
        w="270px"
        mx="auto"
      >
        
        <Flex
          onClick={() => setType('nba')}
          flex="1"
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nba' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NBA
        </Flex>
          
        <Flex 
          flex="1"
          onClick={() => setType('mlb')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'mlb' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          MLB
        </Flex>
        <Flex 
          flex="1"
          onClick={() => setType('nfl')}
          alignItems="center"
          justifyContent="center"
          borderRadius="32px"
          bg={type === 'nfl' ? 'var(--chakra-colors-white40)' : 'transparent'}
          cursor="pointer"
        >
          NFL
        </Flex>
      </Flex>

      <Box>
        {Object.entries(groupedByTeam).map(([team, players]) => (
          <TeamPlayers key={team} team={team} players={players} />
        ))}
      </Box>
      
    </Box>
  )
}

export default PlayersListPage


const TeamPlayers = ({ players, team }) => {

  const listRef = useRef()

  const onSave = useCallback(
    async e => {
      const { team  } = e.target.dataset

      const elems =  listRef.current?.querySelectorAll(`[data-player]`)
      
      for (const elem of elems) {
        try {
          const dataUrl = await htmlToImage.toPng(elem);
          const { name } = elem.dataset;
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = `${team} - ${name}.png`;
    
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (err) {
          console.log(err);
        }
      }
    },
    []
  )

  
  return (
    <Box mt="40px">
      <Center fontSize="20px" fontWeight="600">{team}</Center>
      <Flex
        flexWrap="wrap"
        rowGap="16px"
        columnGap="4px"
        justifyContent="center"
        mt="20px"
        ref={listRef}
      >
        {players.map(p => (
          <GameCard key={p.rapid_id} player={p} placed flipped hideBack />    
        ))}
      </Flex>
      <Flex justifyContent="center" mt="6px">
        <Button 
          onClick={onSave} 
          size="sm"
          mt="20px"
          variant="blue" 
          data-team={team}
        >
          Download
        </Button>
      </Flex>
    </Box>
)}
  