import { Box, Button, Flex, Text } from '@chakra-ui/react'
import GameCard from './GameCard'
import Prize from 'components/prize/Prize'
import { useEffect, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'


const GameBoard = props => {
  const { 
    onSwap, hasSwap, userSwap, presented, withPoints, league, prize, disabled,
    banner, players, selectedIds = [], setSelectedIds, onComplete, isDisabled 
  } = props

  const [playersIds, setPlayersIds] = useState([])
  const [placed, setPlaced] = useState(disabled)
  const [flippedCards, setFlippedCards] = useState([])
  const [animCompleted, setAnimCompleted] = useState(true)
  const [flipDuraion, setFlipDuration] = useState(2000)
  const [isSwapMode, setIsSwapMode] = useState(false)
  const [swappedCards, setSwappedCards] = useState([])

  const width = Math.min(window.innerWidth, 400) - 28
  const canSwap =  !hasSwap && userSwap > 0
  const flipped = playersIds.length > 0 && flippedCards.every(v => v)

  useEffect(
    () => {
      if (!presented) {
        return
      }
      const newPlayersIds = players.map(p => p.rapid_id)
      if (!isEqual(newPlayersIds, playersIds)) {
        const flippedCards = newPlayersIds.map((v, i) => v === playersIds[i])
        setFlippedCards(flippedCards)
        setPlayersIds(newPlayersIds)
        setAnimCompleted(false)
      }
    },
    [players, presented]
  )
  
  useEffect(
    () => { setSwappedCards([]) },
    [isSwapMode] 
  )

  useEffect(
    () => {
      if ((playersIds?.length || 0) === 0) return
      if (presented && !disabled && !placed) {
        setTimeout(() => setPlaced(true), 50)
      }
    },
    [presented, playersIds, disabled]
  )

  useEffect(
    () => {
      if (placed || !animCompleted) {
        const promises = flippedCards.map(v => {
          if (v) return Promise.resolve()
          return new Promise(res => {
            setTimeout(() => res(), flipDuraion)
          })
        })
        Promise.all(promises)
          .then(
            () => {
              setFlipDuration(0)
              
              setFlippedCards(flippedCards.map(() => true))
              setAnimCompleted(true)
            }
          )
      }
    },
    [placed, animCompleted]
  )

  return (
    <Box>
      {!disabled && (
        <Box>
          <Flex
            color="#78BEFF"
            fontWeight="800"
            fontSize="32px"
            align="center"
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            opacity={flipped ? 1 : 0}
            transition="opacity 1s ease-out"
          >
            {isSwapMode ? 'Swap cards' : banner[0]}
          </Flex>
          <Box
            // h="21px"
            mt="10px"
            position="relative"
            color="var(--chakra-colors-white80)"
            fontWeight="700"
            fontSize="18px"
            lineHeight="21px"
            textAlign="center"
          >
            <Box
              position="relative"
              top="0"
              left="0"
              right="0"
              opacity={flipped ? 1 : 0}
              transition="opacity 1s ease-out"
            >
              {isSwapMode ? 'Tap the cards you want to re-deal' : banner[1]}
            </Box>
            <Box
              position="relative"
              top="0"
              left="0"
              right="0"
              opacity={flipped ? 0 : 1}
              transition="opacity 1s ease-out"
              animation="flashanim .5s ease-in-out infinite alternate"
            >
               Every player is dealt different cards at random
            </Box>
          </Box>
          
        </Box>
      )}
      <Flex mt="20px" justifyContent="center" flexWrap="wrap" gap="11px">
        {players.map((player, index) => (
          
          <Box key={player.rapid_id}>
            <GameCard 
              width={width}
              index={index}
              placed={placed}
              flipped={disabled || flippedCards[index]}
              player={player}
              gtd={player.gtd}
              disabled={isSwapMode || disabled}
              grayScaled={!disabled || isSwapMode}
              selected={isSwapMode ? false : selectedIds.indexOf(player.rapid_id) !== -1}
              swapSelected={swappedCards.indexOf(player.rapid_id) !== -1}
              onSelect={() => {
                if (!placed || !flipped) { 
                  return
                }
                if (disabled) return
                if (isSwapMode) {
                  if (swappedCards.indexOf(player.rapid_id) === -1) {
                    setSwappedCards([...swappedCards, player.rapid_id])
                  } else {
                    setSwappedCards(swappedCards.filter(id => id !== player.rapid_id))
                  }
                  return
                }
                if (selectedIds.indexOf(player.rapid_id) === -1) {
                  if (selectedIds.length < 3) {
                    setSelectedIds([...selectedIds, player.rapid_id])
                  }
                } else {
                  setSelectedIds(selectedIds.filter(id => id !== player.rapid_id))
                }
              }}
            />
             {withPoints && (
              <Box 
                fontSize="16px" 
                fontWeight="600"
                mt="8px" 
                lineHeight="16px" 
                textAlign="center"
              >{player.points}</Box>
            )}
          </Box>
          
        ))}
      </Flex>
      {!disabled && (
        <>
          <Flex
            opacity={flipped ? 1 : 0} 
            justifyContent="center" 
            mt="30px"
            gap="10px"
            transition="opacity 1s ease-out"
          >
            {isSwapMode ? (
              <>
                <Button 
                  variant="blue"
                  isDisabled={swappedCards.length === 0}
                  onClick={() => {
                    if (isDisabled) return
                    setFlippedCards(
                      playersIds.map(id => swappedCards.indexOf(id) === -1)
                    )
                    onSwap(swappedCards)
                    setSelectedIds([])
                    setIsSwapMode(false)
                  }}
                >Swap selected</Button>
                <Button 
                  onClick={e => {
                    setIsSwapMode(false)
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button 
                  isDisabled={isDisabled || selectedIds.length < 3}
                  variant="blue"
                  onClick={onComplete}
                >Enter</Button>

                {canSwap ? (
                  <Button 
                    display="block"
                    onClick={e => {
                      e.preventDefault()
                      setIsSwapMode(true)
                    }}
                  >
                    <Box>
                      Swap cards
                    </Box>
                    <Box
                      fontSize="13px"
                      fontWeight="600"
                    >
                      {userSwap} left
                    </Box>
                  </Button>
                ) : (
                  <Button 
                    as={Link}
                    to={routes.referralSetting}
                  >
                    Get swaps
                  </Button>
                )}
                
                
                  
                
              </>
            )}
          </Flex>

          {prize && (
            <>
              <Box mt="50px">
                <Text
                  fontSize="24px"
                  fontWeight="800"
                  align="center"
                >Tonight’s prize</Text>
              </Box>
              <Box mt="20px">
                <Prize league={league} mininal prize={prize} />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default GameBoard