import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import routes from 'constants/routes'
import * as Sentry from '@sentry/react'

import { useLazyGetUserProfileQuery } from 'store/services/users'
import { selectUser, setIsAuthenticated, setUser } from 'store/slices/authSlice'

import MainPage from 'pages/MainPage';

import LeaderboardPage from 'pages/LeaderboardPage'
import PrizesPage from 'pages/PrizesPage'
import { Box, Button, Text } from '@chakra-ui/react'
import Header from 'components/layout/Header'
import Footer from 'components/layout/Footer'
import RulesPage from 'pages/RulesPage'
import GamePage from 'pages/GamePage'
import SignInPage from 'pages/SignInPage'
import ResultPage from 'pages/ResultPage'
import Winners from 'pages/Winners'
import RefferalPage from 'pages/Refferal'
import RefferalSettingsPage from 'pages/RefferalSettingsPage'
import SettingsPage from 'pages/SettingsPage'
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage'
import SweepstakesOfficialRulesPage from 'pages/SweepstakesOfficialRulesPage'
import ScrollToTop from 'components/ScrollToTop'
import { logError } from 'utils/logger'
import PlayersListPage from 'pages/PlayersListPage'

function App() {
  const [getMe] = useLazyGetUserProfileQuery()
  const [init, setInit] = useState(false)
  const dispatch = useDispatch()
  const [failFetchUser, setFailFetchUser] = useState(false)
  const user = useSelector(selectUser)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.setUser(user ? { id: user.id } : null)
    }
  }, [user])
  
  
  useEffect(
    () => {
     
      const onLoad = async () => {
        try {
          const { data: user, error } = await getMe()
          if (error && error.status !== 401) {
            logError(error, {
              component: 'App',
              place: 'getMe http request'
            })
            setFailFetchUser(true)
          } else {
            dispatch(setUser(user))
            dispatch(setIsAuthenticated())
          }
        } catch (err) {
          logError(err, {
            component: 'App',
            place: 'getMe'
          })
          setFailFetchUser(true)
        }
        setInit(true)
      }
      onLoad()
    },
    []
  )

  if (!init) return null

  if (failFetchUser) {
    return (
      <Box
        h="100vh"
        w="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        px="14px"
      >
        <Box
          textAlign="center"
        >
          <Text
            mb="20px"
          >Something went wrong,<br />but don’t fret — let’s give it another shot.</Text>
        
          
          <Button
            variant="blue"
            mx="auto"
            onClick={e => {
              window.location.reload()
            }}
          >
            Try again
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <ScrollToTop />
      <Header  />
      <Box 
        position="relative"
        minH="calc(100vh - 47px)"
        pt="30px"
        pb="40px"
        px="14px"
      >
        <Routes>
          <Route path={routes.home} element={<MainPage />} />
          <Route path={routes.rules} element={<RulesPage />} />
          <Route path={routes.prizes} element={<PrizesPage />} />
          <Route path={routes.game} element={<GamePage />} />
          <Route path={routes.signin} element={<SignInPage />} />
          <Route path={routes.leaderboard} element={<LeaderboardPage />} />
          <Route path={routes.result} element={<ResultPage />} />
          <Route path={routes.winners} element={<Winners />} />
          <Route path={routes.settings} element={<SettingsPage />} />
          <Route path={routes.user} element={<SettingsPage />} />
          <Route path={routes.referralSetting} element={<RefferalSettingsPage />} />
          <Route path={routes.referral} element={<RefferalPage />} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={routes.sweepstakesOfficialRules} element={<SweepstakesOfficialRulesPage />} />
          <Route path={routes.playersList} element={<PlayersListPage />} />
          
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
